import React from 'react'
import Api from "../../services/Api"
import Loading from "../Loading";
import {ChatInterface} from "../../interfaces/Chat";
import ChatWindow from "../ChatWindow";
import WebSocketClient from "../../services/WebSocketClient";
import Breadcrumbs from "../menu/Breadcrumbs";
import {LogEventInterface, LogEventType} from "../../interfaces/LogEvent";
import {CoachInterface} from "../../interfaces/Coach";
import {PackageType} from "../../interfaces/Package";
import { TrVar } from '../../services/translate';

interface ChatPartnerInterface {
  personId: number
  firstName: string
  lastName: string
  picture: string
}
interface ComponentProps {
  coachId: number
  isClientActive?: boolean
  clientHasCoach?: boolean
  close?: Function
}

interface ComponentState {
  coach: CoachInterface
  activeCoach: boolean
  filteredChats: Array<ChatInterface>
  chatWithZebrain: boolean
  chatPartners: Array<ChatPartnerInterface>
  chatPartnerId: number
  chatPartnerName: string
  chatPartnerPicture: string
  loaded: boolean
  licenseAllowsChat: boolean
  packageType: PackageType
}

export default class ClientChatOne extends React.Component<ComponentProps, ComponentState>  {

  private api = Api.getInstance()
  private webSocketClient = WebSocketClient.getInstance()
  chats: Array<ChatInterface> = []

  state: Readonly<ComponentState> = {
    coach: {} as CoachInterface,
    activeCoach: false,
    filteredChats: [],
    chatPartners: [],
    chatPartnerId: 0,
    chatPartnerName: '',
    chatPartnerPicture: '',
    chatWithZebrain: false,
    loaded: false,
    licenseAllowsChat: false,
    packageType: 0
  }

  componentDidMount() {
    this.webSocketClient.webSocket?.addEventListener("message", this.messageListener)
    this.getData()
    const data: LogEventInterface = {
      eventType: LogEventType.PAGE_VIEW,
      eventData: {name: 'chat', coach: this.props.coachId}
    }
    const url = 'client_log_event'
    this.api.post(url, data).then()
  }

  componentWillUnmount() {
    this.webSocketClient.webSocket?.removeEventListener("message", this.messageListener)
  }

  private getData = () => {
    // HD removed 2024-12-03 - clients couldn't see archived chats
    // if (!this.props.clientHasCoach) {
    //   this.setState({loaded: true})
    //   return
    // }
    let url = 'coach_chat/client/get/' + this.props.coachId

    this.api.get(url).then(response => {
      let chatPartnerId = 0
      let chatPartnerName = 'Zebrain coach'
      let chatPartnerPicture = '/assets/icon/favicon.svg'
      if (response.json.coach) {
        chatPartnerId = response.json.coach.personId
        chatPartnerName = response.json.coach.firstName + ' ' + response.json.coach.lastName
        chatPartnerPicture = response.json.coach.picture
      }
      this.setState({
        coach: response.json.coach,
        activeCoach: response.json.activeCoach,
        licenseAllowsChat: response.json.licenseAllowsChat,
        chatPartners: response.json.chatPartners,
        chatPartnerId: chatPartnerId,
        chatPartnerName: chatPartnerName,
        chatPartnerPicture: chatPartnerPicture,
        packageType: response.json.packageType,
        filteredChats: response.json.chats,
        loaded: true
      })
    })
  }

  messageListener = (event: any) => {
    const mess = JSON.parse(event.data)
    if (mess.type === 'client') {
      if (mess.subType === 'chat') {
        mess.chat.chatWithZebrain = false;
        this.addChat(mess.chat)
      }
    }
  }

  toDate = (d: string) => {
    return new Date(d)
  }

  addChat = (chat: ChatInterface) => {
    const filteredChats = this.state.filteredChats
    filteredChats.push(chat)
    this.setState({filteredChats: filteredChats})
  }

  sendChat = (message: string) => {
    if (message.length > 0) {
      const data = {
        message: message,
        send_to_coach: true,
      }
      let url = 'coach_chat/client/save'
      this.api.post(url, data).then(response => {
        this.addChat(response.json.chat)
      })
    }
  }

  close = () => {
    if (this.props.close) {
      this.props.close()
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <Loading/>
      )
    }

    let breadcrumbs: any = [{name: this.api.trTxt(TrVar.SelectChat), link: '/app/client/chat'}]
    let name = this.state.chatPartnerName


    const sendEnabled = !!(this.props.isClientActive && this.state.activeCoach)

    return (
      <div className="page_content " >
        <Breadcrumbs breadcrumbs={breadcrumbs} name={name}/>
        {
          this.props.isClientActive !== true &&
            <p className="top_margin">
              {this.api.trTxt(TrVar.AccountInactive)} {this.api.trTxt(TrVar.CannotWriteMessages)}
            </p>
        }

        <div className="page_section r-flex justify-between" >
          {
            this.props.isClientActive &&
            <div style={{width: "min(330px, 100vw - 64px)"}}>
              <div className="flex items-center cursor-pointer"
                   style={{maxWidth: "495px"}}>
                <img alt="coach" className="circle_large" src={this.state.chatPartnerPicture}/>
                <div className="left_margin overflow-hidden color_neutral_400" style={{display: 'inline'}}>
                  {
                    (this.state.activeCoach) ?
                      (
                        <div>
                          {this.api.trTxt(TrVar.YourCoach)}
                        </div>
                      ) : (
                        <div className="color_neutral_400">
                          {this.api.trTxt(TrVar.Archived)}
                        </div>
                      )
                  }
                  <h2>
                    {this.state.chatPartnerName}
                  </h2>

                </div>
              </div>
              {
                this.state.packageType === PackageType.ACCESS &&
                <div className="top_margin">
                    <div className="flex items-center">
                      <img alt="check" className="icon_small" src="/assets/icon/white/check.svg"/>
                      <p className="ml-3">
                          {this.api.trTxt(TrVar.WriteToYourCoachWhenever)}
                      </p>
                    </div>
                    <div className="flex items-center mt-2">
                      <img alt="check" className="icon_small" src="/assets/icon/white/check.svg"/>
                      <p className="ml-3">
                          {this.api.trTxt(TrVar.YourCoachRepliesThrice)}
                      </p>
                    </div>
                </div>
              }
            </div>
          }
          <div className="r-ml-12 r_top_margin w-full flex justify-around">
            <ChatWindow chatMessages={this.state.filteredChats} onChat={this.sendChat} standalone={true}
                        chatWithZebrain={this.state.chatWithZebrain}
                        isSendEnabled={sendEnabled}/>
          </div>
        </div>


      </div>
    )
  }
}
